import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loader } from "../components/Loader";
import SignUpInput from "../components/SignUpInput";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export const QuestionPage = ({ loggedIn }) => {
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState({
    question: "",
  });

  const navigate = useNavigate();

  const notifyError = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const askQuestion = async () => {
    setLoader(true);
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    const { question } = inputs;

    let body = {
      question,
    };

    console.log(config);

    try {
      const response = await axios.post(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/addTosheet",
        body,
        config
      );
      if (response.data.success === true) {
        // console.log(response);
        notifySuccess(response.data.msg);
      } else {
        // console.log(response);
        notifyError(response.data.msg);
      }
    } catch (err) {
      // console.log(err);
      notifyError(err.data.data.msg);
    }
    setInputs({
      question: "",
    });
    setLoader(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    askQuestion();
  };

  useEffect(() => {
    if (!localStorage.getItem("token") || loggedIn === false) {
      navigate("/Login");
    }
  }, []);

  return (
    <div className="signUp">
      {loader && <Loader />}
      <div className="loginForm">
        <div className="SFtitle LFtitle">Ask your questions</div>
        <form onSubmit={handleSubmit} className="form">
          <SignUpInput
            name="question"
            labelName="Question"
            type="text"
            maxLength="200"
            className="lemail"
            value={inputs?.question}
            onChange={handleChange}
          />
          <div className="submitContainer">
            <button className="submit" type="submit">
              ASK
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
