import React from "react";
import "../css/TSWorkshop.css";
import TSWsHeader from "../components/TSWsHeader";
import TsNavbar from "../components/TsNavbar";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const TSWorkshop = () => {
  const navigate = useNavigate(); // Initialize useNavigate for internal navigation

  // Function to handle Register Now button click
  const handleRegisterClick = () => {
    navigate("/"); // Navigate to the desired page, e.g., homepage
  };

  return (
    <div className="workshop">
      <TsNavbar />
      <TSWsHeader />

      <div className="TechsummitSession1BtnContainer">
        <button className="TSS4button" onClick={handleRegisterClick} disabled >
          Register Now
        </button>
      </div>

      <div className="effect1"><br></br><br></br><br></br><br></br></div>
    </div>
  );
};

export default TSWorkshop;
