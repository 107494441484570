import React from "react";
import "../css/homePage/HomePageTechSummitCard.css";
import HPTSimg from "../assets/images/homePage/HPTSimg.png";
import { Link } from "react-router-dom";

export default function HomePageTechSummitCard() {
  return (
    <div className="HPTScardContainer">
      <div className="HPTScard">
        <div className="HPTScardContent1 HPTScardContent3">
          <p className="HPTSp1">
            Discover numerous exciting <b className="HPTSb1">Research Sessions
            </b>
          </p>
          <p className="HPTSp2">
            A <b className="HPTSb1">UI/UX Hackathon</b> to unleash your creativity

          </p>
          <p className="HPTSp1">
          An insightful{" "}
            <b className="HPTSb1"> Tech Talk</b> by industry leaders

          </p>
        </div>
        <div className="HPTSflexContainer">
          <div className="HPTScardTitle">
            <h1 className="HPTSh1">IEEE MONTH</h1>
            <p className="HTPScardTitlep">1st October - 31st October</p>
          </div>
          <div className="HPTSimgContainer">
            <img src={HPTSimg} className="HPTSimg" alt="" />
          </div>
        </div>
        <div className="HPTScardContent1 HPTScardContent2">
          <p className="HPTSp1">
          Get to know <b className="HPTSb1">IEEE</b> – what it does and its impact over <b className="HPTSb1">25 Years
          </b>
          </p>
          <p className="HPTSp2">
          Explore the domains of{" "}
            <b className="HPTSb1">App Development, Web Technologies, and AI
            </b>
          </p>
          <p className="HPTSp1">
          Opportunities to connect via multiple
            <b className="HPTSb1"> Interactive Sessions</b>
          </p>
        </div>
        <div className="TechsummitSession3BtnContainer HPTSbtnContainer">
          <Link to="IEEEMonth">
            <button className="TSS3button HPTSbtn">View More</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
