import React from "react";
import { Link } from "react-router-dom";

function EventCard1(props) {
  return (
    <div className="Econtainer">
      <div className="Eanime1">
        {/* <Link to={"/event/getevent/" + props.id} className="linkContainer"> */}
        <img src={props.url} alt={props.url} className="Eanimation1" />
        {/* </Link> */}
      </div>
      {/* <p>{props.key}</p> */}
      <div className="ECard1">
        {/* <Link to={"/event/getevent/" + props.id} className="linkContainer"> */}
        <h3 className="EcardTitle1">{props.title}</h3>
        <p className="EcardDescription1">{props.description}</p>
        {/* </Link> */}
      </div>
    </div>
  );
}

function EventCard2(props) {
  return (
    <div className="Econtainer">
      {/* <p>{props.key}</p> */}
      <div className="ECard2">
        {/* <Link to={"/event/getevent/" + props.id} className="linkContainer"> */}
        <h3 className="EcardTitle2">{props.title}</h3>
        <p className="EcardDescription2">{props.description}</p>
        {/* </Link> */}
      </div>
      <div className="Eanime2">
        {/* <Link to={"/event/getevent/" + props.id} className="linkContainer"> */}
        <img src={props.url} alt={props.url} className="Eanimation2" />
        {/* </Link> */}
      </div>
    </div>
  );
}

export { EventCard1, EventCard2 };
