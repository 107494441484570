import React, { useEffect } from "react";
import "../css/homePage/HomePageHeader.css";

export const HomePageHeader = () => {
  useEffect(() => {
    const ticketElm = document.querySelector(".HPHtitle");
    if (!ticketElm) return;
    const { x, y, width, height } = ticketElm.getBoundingClientRect();
    if (x && y && width && height) {
      const centerPoint = { x: x + width / 2, y: y + height / 2 };

      window.addEventListener("mousemove", (e) => {
        const degreeX = (e.clientY - centerPoint.y) * 0.008;
        const degreeY = (e.clientX - centerPoint.x) * -0.008;

        ticketElm.style.transform = `perspective(1000px) rotateX(${degreeX}deg) rotateY(${degreeY}deg)`;
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="HPHContainer">
      <div className="HPHtitleContainer">
        <div className="HPHtitle">
          <p className="HPtitleBig">IEEE</p>
          <p className="HPtitleSmall">SB VIT PUNE</p>
        </div>
        {/* <div className="HPHsubtitle">Advancing Technology towards Humanity</div> */}
      </div>
    </div>
  );
};
export default HomePageHeader;
