import React from "react";
import "../css/aboutUs/Wholeteam.css";
import LImage from "../assets/images/aboutUs/linkedInImage.svg";
import GitImage from "../assets/images/aboutUs/GithubImage.svg";

function Coreteam(props) {
  const { linkedinc, imagec, positionc, namec, gitc } = props.data;

  return (
    <div className="coreteam">
      <div className="bgo">
        <img className="images" src={imagec} alt="" />
      </div>
      <div className="overlay">
        <a
          className="link"
          href={`${linkedinc}`}
          target="_blank"
          rel="noreferrer"
        >
          <img className="LImage" src={LImage} alt=""></img>
        </a>
        <a className="link" href={`${gitc}`} target="_blank" rel="noreferrer">
          <img className="GitImage" src={GitImage} alt=""></img>
        </a>
      </div>
      <p className="AUname">{namec}</p>
      <p className="AUposition">{positionc}</p>
    </div>
  );
}

export default Coreteam;
