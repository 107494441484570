import React from "react";
import "../css/aboutUs/Wholeteam.css";
import LImage from "../assets/images/aboutUs/linkedInImage.svg";
import GitImage from "../assets/images/aboutUs/GithubImage.svg";

function Execomteam(props) {
  const { linkedine, imagee, positione, namee, gite } = props.data1;

  return (
    <div className="execomteam">
      <div className="bgo">
        <img className="images" src={imagee} alt="" />
      </div>
      <div className="overlay">
        <a
          className="link"
          href={`${linkedine}`}
          target="_blank"
          rel="noreferrer"
        >
          <img className="LImage" src={LImage} alt=""></img>
        </a>
        <a className="link" href={`${gite}`} target="_blank" rel="noreferrer">
          <img className="GitImage" src={GitImage} alt=""></img>
        </a>
      </div>
      <p className="AUname">{namee}</p>
      <p className="AUposition">{positione}</p>
    </div>
  );
}

export default Execomteam;
