import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import DevelopersMapping from "../components/DevelopersMapping";
import "../css/developers/Developers.css";
import DevelopersParticle from "../components/DevelopersParticle";
import DevelopersData from "../components/Developers";


function Developer() {
  const el = useRef(null);

  const videoRef = useRef();
  useEffect(() => {
    videoRef.current.playbackRate = 0.5;
  }, []);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [" DEVELOPERS. ", " DESIGNERS.", " CREATORS."],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2000,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <div className="developerContainer">
      <div className="banner-container developer-video">
        <video
          className="banner_video"
          ref={videoRef}
          
          // preload={"auto"}
          autoPlay
          loop
          muted
          playsInline
        />
        <div className="overlay-contents">
          <div className="developerTitleContainer">
            <h1 className="developerTitle">WE ARE</h1>
            <h1 className="typedText">
              <i>
                <span ref={el} />
              </i>
            </h1>
          </div>
        </div>
      </div>
      <div className="developerContentContainer">
        <div className="developerInfo">
          <p className="developerInfo1">OUR AMAZING DEVELOPERS</p>
        </div>
      </div>
      <DevelopersParticle />
      <DevelopersMapping data={DevelopersData} />
    </div>
  );
}

export default Developer;
