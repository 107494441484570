import React from "react";
// import "../css/TSWsHeader.css";
// import phoneimage from "../assets/images/wsphoneimage.svg";
import "../css/TSWorkshop.css";
import fingerPrint from "../assets/images/workshop/Research.png";

function TSWsHeader() {
  return (
    <div className="tswsheader">
      <div className="TSWSbackground">
        <p className="wsheading">
          <b>RESEARCH SESSIONS</b>
        </p>
      </div>
      <p className="wsdesc1">
      Join the IEEE Research Sessions to uncover key insights into research, from innovation and paper writing to navigating journals and conferences. These sessions will help you discover practical ways to transform your projects into research papers while being a part of a supportive community.

      </p>
      <div className="containertswsheader">
        <div className="headerleft">
          <p className="wstiming">
          IEEE Research Sessions :<br></br>
            20, 21, 22, 23, 24 OCT 2024<br></br>
            7:00 PM to 7:30 PM <br></br>
          </p>
        </div>
        <div className="headerright">
          <img className="phoneimage" src={fingerPrint} alt="" />
        </div>
      </div>
      <p className="wsdesc2">
      We offer a comprehensive introduction to research and design thinking, covering key topics such as innovation, the design thinking project lifecycle, and transforming ideas into research papers. Our sessions will guide you through the intricacies of conferences and journals, providing essential resources like lists of reputable conferences and publication formats. You'll be able to connect with peers through a dedicated WhatsApp group for collaboration, project support, and knowledge sharing. Additionally, we address ethical considerations in research, including plagiarism prevention and the use of AI tools.
      </p>
    </div>
  );
}

export default TSWsHeader;
