import React, { useEffect } from "react";
import ai_ml from "../assets/images/domain/ai_ml.png";
import app_dev from "../assets/images/domain/app_dev.png";
import blockchain from "../assets/images/domain/blockchain.png";
import coding_club from "../assets/images/domain/coding_club.png";
import cyber_security from "../assets/images/domain/cyber_security.png";
import iot from "../assets/images/domain/iot.png";
import web_dev from "../assets/images/domain/web_dev.png";
import research from "../assets/images/domain/research.png";

export default function DomainCircleMobile(props) {
  const { rotate } = props;

  function rotateImageClockWise() {
    var myImage = document.getElementById("circleContainerMobile");
    myImage.style.transform = `rotate(${45 * rotate}deg)`;
  }

  useEffect(() => {
    rotateImageClockWise();
    // eslint-disable-next-line
  }, [rotate]);

  return (
    <div id="circleContainerMobile">
      <div className="DCMsrow1">
        <img src={blockchain} className="DCMitem DMCblockchain" alt="" />
        <img src={research} className="DCMitem DMCresearch" alt="" />
      </div>
      <div className="DCMsrow2">
        <img src={app_dev} className="DCMitem DMCapp" alt="" />
        <img src={iot} className="DCMitem DMCiot" alt="" />
      </div>
      <div className="DCMsrow3">
        <img src={ai_ml} className="DCMitem DMCai" alt="" />
        <img src={cyber_security} className="DCMitem DMCcyber" alt="" />
      </div>
      <div className="DCMsrow4">
        <img src={web_dev} className="DCMitem DMCweb" alt="" />
        <img src={coding_club} className="DCMitem DMCcoding" alt="" />
      </div>
    </div>
  );
}
