import React from "react";
import profile from "../assets/images/speakers/Speaker_prof.png";
import instagramSVG from "../assets/images/speakers/instagram.png";
import linkedinSVG from "../assets/images/speakers/linkedin.png";


import "../css/Speakers.css";

const SpeakerCardRight = (props) => {
  // console.log(props);
  return (
    <div>
      <div className="speaker-card-right-container">
        <div className="name-holder-R">
          <p className="name-R">{props.name}</p>
          <p className="designation-R">{props.designation}</p>
        </div>
        <div className="bottom-part-R">
          <div className="profile-picture-div-R">
            <img className="profile-image-R" src={props.image} alt="" />
          </div>
          <div className="right-part-R">
            <div className="description-R">{props.description}</div>
            <div className="icons-R">
              <a href={props.linkedIn} target="_blank" rel="noreferrer">
                <img src={linkedinSVG} alt="" />
              </a>
              <a href={props.insta} target="_blank" rel="noreferrer">
                <img src={instagramSVG} alt="" />
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SpeakerCardRight };
