import React, { useState, useEffect } from "react";
import DomainCircle from "./DomainCircle";
import DomainInfo from "./DomainInfo";
import DomainScroll from "./DomainScroll";
import "../css/homePage/Domain.css";
import "../css/homePage/DomainsMediaQuery.css";
// import Fade from "react-reveal/Fade";

import ai_ml from "../assets/images/domain/ai_ml.png";

export default function DomainLaptop() {
  const textArr = [
    "AI - ML",
    "APP DEV",
    "BLOCKCHAIN",
    "CODING CLUB",
    "CYBER SECURITY",
    "IOT",
    "RESEARCH",
    "WEB DEV",
  ];

  const infoArr = [
    "IEEE is absolutely aware of the rising significance of Al. Owing to its numerous applications, the club has even uploaded its own YouTube playlists of various Al based mini projects. In a recent workshop based on Neural Networks, the attendees were also taught to build a traffic recognition system right from scratch",
    "Wanna engage well with your audience? Apps are one of the best ways to keep your audience engaged and committed. We also have our own master app dev team who are all-set to launch our very own app. Some of our previous exemplars in this field include a project on developing a Stock Inventory management app for APMC.",
    "With its decentralised nature, blockchain can lead us to better opportunities and benefit the businesses with enhanced security and transparency. This technology has evolved greatly in all possible sectors. IEEE had arranged an insightful workshop in which we covered everything between cryptocurrency to building Smart Contracts.",
    "One of our blockbusters, the coding club consists of all the enthusiastic programming geeks. The like-minded peeps meet twice a week in sessions organized exclusively for IEEE members. The instructors focus on helping you ace high profile questions solved from sites like hacker-rank, along with various fun activities and competitions.",
    "We live in the digital era. As a result, several frauds and malicious attacks have been documented. Cybersecurity is required to preserve the internet era and It protects you from cybercriminals. IEEE organises influential webinars and workshops to help the attendees understand more about cyber security",
    `Internet-of-Things (IoT) gadgets are all around us, continually transferring data and "talking" to one another. Virtual assistants, "smart" electronics, and wearable health trackers are all examples of IoT devices that we encounter on a daily basis. Each Internet-of- Things device collects data in real time and transmits it to us in order to make our lives more efficient.`,
    "When tasked with finding a solution to a problem, research helps identify, assess, and collate. In universities, the research component allows for a more comprehensive educational experience. Our Research team is dedicated to keep you updated with the latest developments in the R&D sector of the technical domain in every possible way.",
    "IEEE VIT SB Pune actively indulges in one of the most lucrative & fast- growing technologies - web development. Few of our previous triumphs on this domain include the JavaScript workshop and Avenir - the frontend web dev competition, to name a few. The club also has its own team of web experts who undertake various industrial web dev projects.",
  ];

  const [scrollLeft, setScrollLeft] = useState(null);

  const [state, setState] = useState({
    center: ai_ml,
    text: "AI - ML",
    info: "IEEE is absolutely aware of the rising significance of Al. Owing to its numerous applications, the club has even uploaded its own YouTube playlists of various Al based mini projects. In a recent workshop based on Neural Networks, the attendees were also taught to build a traffic recognition system right from scratch",
    count: 0,
  });

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter + 1);
    }, 6000);
    return () => clearTimeout(timer);
  }, [counter]);

  useEffect(() => {
    setState({
      center: ai_ml,
      text: textArr[counter % 8],
      info: infoArr[counter % 8],
      count: counter + 1,
    });
  }, [counter]);

  return (
    <div className="domain">
      {/* <Fade bottom> */}
      <div className="domainContainer">
        <DomainInfo state={state} scrollLeft={scrollLeft} />
        <DomainCircle state={state} />
      </div>
      {/* <DomainScroll
        scrollLeft={scrollLeft}
        setScrollLeft={setScrollLeft}
        state={state}
        setState={setState}
      /> */}
      {/* </Fade> */}
    </div>
  );
}
