import React from "react";
import "../css/footer/Card.css";
import IEEE_logo2 from "../assets/images/footer/ieeeLogo.png";
import gif from "../assets/images/card/right2_.gif";
import gif2 from "../assets/images/card/right2_.gif";
// import Fade from "react-reveal/Fade";

const Card = () => {
  return (
    <>
      <div className="space"></div>
      <div className="main">
        <div className="upper">
          {/* <Fade bottom> */}
          <div className="left1">
            <div className="bottle">
              <div className="logo_">
                <img className="IEEE_logo_" src={IEEE_logo2} alt="IEEE_logo" />
              </div>
            </div>
            <div className="HPCcontainer">
              {" "}
              <img className="leftgif" src={gif2} alt="GIF" />
            </div>
          </div>

          <div className="right1">
            <h1 className="heading1_">
              Institute Of Electrical and Electronics Engineers
            </h1>
            <p className="para1">
              The Institute of Electrical and Electronics Engineers (IEEE) is an
              International organization, which has been the nexus for
              propagating scientific expertise among people all over the globe.
              IEEE continues to attract students, faculty and professionals from
              various fields all around the world and is committed to
              incorporating diversity in thoughts which is essential for
              scientific development.
            </p>
          </div>
          {/* </Fade> */}
        </div>

        {/* <Fade bottom> */}
        <div className="lower">
          <div className="left2">
            <h1 className="heading2_">VIT Pune IEEE Student Branch</h1>
            <p className="para2">
              The Institute of Electrical and Electronics Engineers (IEEE) is an
              International organization, which has been the nexus for
              propagating scientific expertise among people all over the globe.
              IEEE continues to attract students, faculty and professionals from
              various fields all around the world and is committed to
              incorporating diversity in thoughts which is essential for
              scientific development.
            </p>
          </div>
          <div className="right2">
            <img className="gif_right" src={gif} alt="GIF" />
          </div>
        </div>
        {/* </Fade> */}
      </div>
    </>
  );
};

export default Card;
