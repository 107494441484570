import React from "react";
import "../css/TsIdeathon.css";
import ideathonImage from "../assets/images/ideathon/ideathon1.png";
import sponsorLogo from "../assets/images/ideathon/UptiqLogo2.png";
import sponsorLogoMain from "../assets/images/ideathon/UptiqLogoFinal.png";
import TsNavbar from "../components/TsNavbar";
import { useNavigate } from "react-router-dom";

export default function TsIdeathon() {
  const navigate = useNavigate(); // Initialize useNavigate for internal navigation

  // Function to handle Register Now button click
  const handleRegisterClick = () => {
    navigate("/"); // Navigate to EventRegistration page
  };
  return (
    <div className="IdeathonContainer">
      <TsNavbar />
      
      <div className="IdeathonSession2">
        <p className="IdeathonSession2Title">Problem Statement</p>
        <div className="TSdomainContainer">
            <div className="TSdomain TSIdomain1">Develop innovative UI/UX solutions that address climate change and environmental challenges.</div>
          </div>
        </div>
      
      
      
        <p className="IdeathonSession3Title">Hackathon Details</p>
<div className="TSregistrationContainer">
  <div className="TSregistrationFlexContainer">
    <div className="TSRcontainer1">
      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Hackathon Duration:</p>
        <p className="TSRteamSize2">24 hours</p>
      </div>
      
      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Participation Level:</p>
        <p className="TSRteamSize2">Individual</p>
      </div>

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Categories:</p>
        <p className="TSRteamSize2">Open to participants in two categories:</p>
        </div>
        <ul>
    <li className="TSRteamSize2">Junior Category (First-Year Students)</li>
    <li className="TSRteamSize2">Senior Category (Second-Year Students and Third-Year Students)</li>
  </ul>
      
      

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Registration Period:</p>
        <p className="TSRteamSize2">Open from October 12th until the night of October 14th.</p>
      </div>

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Eligibility:</p>
        <p className="TSRteamSize2">Open to individuals in both categories.</p>
      </div>

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Registration Start Date:</p>
        <p className="TSRteamSize2">12th October, 11:00 PM</p>
      </div>

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Registration End Date:</p>
        <p className="TSRteamSize2">14th October, 11:00 PM</p>
      </div>

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Hackathon Start Date:</p>
        <p className="TSRteamSize2">15th October, 6:00 PM</p>
      </div>

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Hackathon End Date:</p>
        <p className="TSRteamSize2">16th October, 6:00 PM</p>
      </div>

      <div className="TSRcontainer2">
        <p className="TSRteamSize1">Submission Activation:</p>
        <p className="TSRteamSize2">15th October, 6:00 PM</p>
      </div>
    </div>
  </div>
</div>

      
<div className="IdeathonSession3">
  <p className="IdeathonSession3Title">RULES & REGULATIONS</p>
  <ul className="IdeathonEligibilityUi">
    <li>
      <b className="TSRteamSize1">Disqualification Clause -</b>
      <br />
      Dual Registration: Any participant found to be registered in both the Junior and Senior categories will be disqualified from the hackathon.
    </li>
    <br />
    <br />
    <li>
      <b className="TSRteamSize1">General Rules -</b>
      <ul>
        <li>
          <b className="TSRteamSize1">Submission Format:</b>
          <ul>
            <li>
              Junior Category: Submissions must be made via a <b className="TSRteamSize1">Figma link</b>.
            </li>
            <li>
              Senior Category: Submissions must be made via a <b className="TSRteamSize1">GitHub link</b>.
            </li>
          </ul>
        </li>
        <li>
          <b className="TSRteamSize1">Design Tools:</b>
          Participants are encouraged to use tools such as <b className="TSRteamSize1">Figma, Adobe XD, Sketch</b>, or any other design software of their choice. For the Senior category, any web development tools may be used.
        </li>
        <li>
          <b className="TSRteamSize1">Duration:</b>
          The hackathon will run for <b className="TSRteamSize1">24 hours</b>. Participants must submit their projects within this timeframe.
        </li>
        <br />
        <br />
      </ul>
    </li>
    <li>
      <b className="TSRteamSize1">Design Guidelines -</b>
      <ul>
        <li>
          <b className="TSRteamSize1">User-Centric Design:</b>
          All solutions should prioritize user experience and accessibility.
        </li>
        <li>
          <b className="TSRteamSize1">Visual Design:</b>
          Participants are encouraged to create aesthetically pleasing designs that align with the theme of <b className="TSRteamSize1">climate change and environmental issues</b>.
        </li>
        <li>
          <b className="TSRteamSize1">Functionality:</b>
          Projects should be functional, providing a working demonstration of the proposed solution.
        </li>
        <br />
        <br />
      </ul>
    </li>
    <li>
      <b className="TSRteamSize1">Submission Criteria (Including Video) -</b>
      <ul>
        <li>
          <b className="TSRteamSize1">Figma Submissions (Junior Category):</b>
          <ul>
            <li>A link to the <b className="TSRteamSize1">Figma prototype</b>.</li>
            <li>A brief description of the project.</li>
            <li>An optional video link showcasing the prototype (maximum length: <b className="TSRteamSize1">3 minutes</b>).</li>
          </ul>
        </li>
        <br />
        <li>
          <b className="TSRteamSize1">GitHub Submissions (Senior Category):</b>
          <ul>
            <li>A link to the <b className="TSRteamSize1">GitHub repository</b>.</li>
            <li>A brief description of the project.</li>
            <li>An optional video link showcasing the website (maximum length: <b className="TSRteamSize1">3 minutes</b>).</li>
          </ul>
        </li>
        <br />
        <li>
          <b className="TSRteamSize1">Video Submission Guidelines:</b>
          <ul>
            <li>Videos should clearly present the project concept, functionality, and how it addresses the problem statement.</li>
            <li>Ensure that the video link is <b className="TSRteamSize1">accessible</b> for judges.</li>
          </ul>
        </li>
      </ul>
    </li>
    <br />
  </ul>
</div>

      <div className="IdeathonSession3">
      <div className="NoteContainer" style={{ fontSize: 20 ,textAlign: 'center', marginBottom: '1rem' }}>
    <p> Note: Registration and submission are closed for the hackathon. </p>
  </div>
      <div className="TechsummitSession1BtnContainer">
            {/* Replaced the anchor link with a button that uses handleRegisterClick */}
            
            <button className="TSS4button" onClick={handleRegisterClick}disabled >
              Register Now
            </button>
            <button className="TSS4button" onClick={handleRegisterClick} disabled >
              Submit
            </button>
            </div>
            {/* <div className="NoteContainer" style={{ fontSize: 20 ,textAlign: 'center', marginBottom: '1rem' }}>
    <p> Below is the PDF of Problem Statement 👇</p>
  </div> */}
      </div>
      {/* Embed the PDF */}
   {/* <div className="PdfContainer">
          <embed 
            src="/docs/EcoInnovate_Hackathon.pdf" 
            type="application/pdf" 
            className="responsive-pdf" 
          />
        </div> */}
    </div>
  );
}
