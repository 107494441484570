import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import "../css/homePage/GalleryHomePage.css";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/core";

import codezest1 from "../assets/images/gallery/CodeZest (2).jpg";
import devops from "../assets/images/gallery/DevOps.jpg";
import gsoc1 from "../assets/images/gallery/GSoC (4).jpg";
import industry_visit1 from "../assets/images/gallery/IndustrialV.jpg";
import python1 from "../assets/images/gallery/PythonWS.jpg";
import social_visit1 from "../assets/images/gallery/SocialV.JPG";
import intromeet from "../assets/images/gallery/Intromeet1.jpg";
import web1 from "../assets/images/gallery/Web3.jpg";
import ieeeday from "../assets/images/gallery/IEEEDay.JPG";
// import Fade from "react-reveal/Fade";

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

export default function Gallery() {
  return (
    <div className="pageHolder">
      {/* <Fade bottom> */}
      <h1 className="homePageGalleryTitle">GALLERY</h1>
      <div className="HPGcontainer">
        <Swiper
          navigation={true}
          effect={"coverflow"}
          centeredSlides={true}
          slidesPerView={window.innerWidth < 768 ? 1 : "auto"}
          loop={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={codezest1} alt="" />
            <p className="caption">CodeZest 22</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={ieeeday} alt="" />
            <p className="caption">IEEE Day</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={devops} alt="" />
            <p className="caption">DevOps Workshop</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={intromeet} alt="" />
            <p className="caption">Intromeet</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={gsoc1} alt="" />
            <p className="caption">GSOC Seminar</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={industry_visit1} alt="" />
            <p className="caption">Industry Visit</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={web1} alt="" />
            <p className="caption">Web 3.0 Seminar</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={python1} alt="" />
            <p className="caption">Python Workshop</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={social_visit1} alt="" />
            <p className="caption">Social Visit</p>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* </Fade> */}
    </div>
  );
}
