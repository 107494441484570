import AaryaDeshpande from "../assets/images/aboutUs/execom/Aarya_Deshpande.png";
import Arnav_Bang from "../assets/images/aboutUs/execom/Arnav_Bang.png";
import Arya_Manve from "../assets/images/aboutUs/execom/Arya_Manve.png";
import Atharav_Kasture from "../assets/images/aboutUs/execom/Atharav_Kasture.png";
import Ayush_Bora from "../assets/images/aboutUs/execom/Ayush_Bora.png";
import Ayush_Girathe from "../assets/images/aboutUs/execom/Ayush_Girathe.png";
import Dhanashree_Petare from "../assets/images/aboutUs/execom/Dhanashree_Petare.png";
import Harsh_Mehta from "../assets/images/aboutUs/execom/Harsh_Mehta.png";
import Janhavi_Gattani from "../assets/images/aboutUs/execom/Janhavi_Gattani.png";
import Nainish_Jaiswal from "../assets/images/aboutUs/execom/Nainish_Jaiswal.png";
import Nitesh_Rajpurohit from "../assets/images/aboutUs/execom/Nitesh_Rajpurohit.png";
import Nitya_Munshi from "../assets/images/aboutUs/execom/Nitya_Munshi.png";
import Pratham_Tomar from "../assets/images/aboutUs/execom/Pratham_Tomar.png";
import prathamesh_anvekar from "../assets/images/aboutUs/execom/prathamesh_anvekar.png";
import Prerak_Gadpayale from "../assets/images/aboutUs/execom/Prerak_Gadpayale.png";
import Purrav_Yagnik from "../assets/images/aboutUs/execom/Purrav_Yagnik.png";
import Rushikesh_gaikar from "../assets/images/aboutUs/execom/Rushikesh_gaikar.png";
import Rutuja_Chaudhari from "../assets/images/aboutUs/execom/Rutuja_Chaudhari.png";
import Sahishnu_G from "../assets/images/aboutUs/execom/Sahishnu_G.png";
import Sakshi_Mahajan from "../assets/images/aboutUs/execom/Sakshi_Mahajan.png";
import Siddharth_Mane from "../assets/images/aboutUs/execom/Siddharth_Mane.png";
import Sudhakar_Shinde from "../assets/images/aboutUs/execom/Sudhakar_Shinde.png";
import Sujal_Thakur from "../assets/images/aboutUs/execom/Sujal_Thakur.png";
import Swastik_Jha from "../assets/images/aboutUs/execom/Swastik_Jha.png";
import Unnati_Vaidya from "../assets/images/aboutUs/execom/Unnati_Vaidya.png";
import Utkarsha_Kavitake from "../assets/images/aboutUs/execom/Utkarsha_Kavitake.png";
import Vaibhav_Panchal from "../assets/images/aboutUs/execom/Vaibhav_Panchal.png";
import vaibhav_pujari from "../assets/images/aboutUs/execom/vaibhav_pujari.png";
import Vansh_Bhatt from "../assets/images/aboutUs/execom/Vansh_Bhatt.png";
import Yash_Kale from "../assets/images/aboutUs/execom/Yash_Kale.png";
import Yogiraj_chaukhande from "../assets/images/aboutUs/execom/Yogiraj_chaukhande.png";

const execom = [
  {
    imagee: AaryaDeshpande,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Aarya Deshpande",
    positione: "Content",
  },
  {
    imagee: Arnav_Bang,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Arnav Bang",
    positione: "Public Media Relations",
  },
  {
    imagee: Arya_Manve,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Arya Manve",
    positione: "Management",
  },
  {
    imagee: Atharav_Kasture,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Atharav Kasture",
    positione: "Technical",
  },
  {
    imagee: Ayush_Bora,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Ayush Bora",
    positione: "Public Media Relations",
  },
  {
    imagee: Ayush_Girathe,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Ayush Girathe",
    positione: "Design & MultiMedia",
  },
  {
    imagee: Dhanashree_Petare,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Dhanashree Petare",
    positione: "Technical",
  },
  {
    imagee: Harsh_Mehta,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Harsh Mehta",
    positione: "Technical",
  },
  {
    imagee: Janhavi_Gattani,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Janhavi Gattani",
    positione: "Design & MultiMedia",
  },
  {
    imagee: Nainish_Jaiswal,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Nainish Jaiswal",
    positione: "Public Media Relations",
  },
  {
    imagee: Nitesh_Rajpurohit ,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Nitesh Rajpurohit",
    positione: "Technical",
  },
  {
    imagee: Nitya_Munshi,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Nitya Munshi",
    positione: "Public Media Relations",
  },
  {
    imagee: Pratham_Tomar,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Pratham Tomar",
    positione: "Technical",
  },
  {
    imagee: prathamesh_anvekar,
    linkedine:
      "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Prathamesh Anvekar",
    positione: "Management",
  },
  {
    imagee: Prerak_Gadpayale,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Prerak Gadpayale",
    positione: "Design & MultiMedia",
  },
  {
    imagee: Purrav_Yagnik,
    linkedine:
      "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Purrav Yagnik",
    positione: "Management",
  },
  {
    imagee: Rushikesh_gaikar,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Rushikesh Gaikar",
    positione: "Technical",
  },
  {
    imagee: Rutuja_Chaudhari,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Rutuja Chaudhari",
    positione: "Finance",
  },
  {
    imagee: Sahishnu_G,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Sahishnu G",
    positione: "Technical",
  },
  {
    imagee: Sakshi_Mahajan,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Sakshi Mahajan",
    positione: "Management",
  },
  {
    imagee: Siddharth_Mane,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Siddharth Mane",
    positione: "Technical",
  },
  {
    imagee: Sudhakar_Shinde,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Sudhakar Shinde",
    positione: "Technical",
  },
  {
    imagee: Sujal_Thakur,
    linkedine:
      "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Sujal Thakur",
    positione: "Management",
  },
  {
    imagee: Swastik_Jha,
    linkedine: "http://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Swastik Jha",
    positione: "Content",
  },
  {
    imagee: Unnati_Vaidya,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Unnati Vaidya",
    positione: "Technical",
  },
  {
    imagee: Utkarsha_Kavitake,
    linkedine:
      "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Utkarsha Kavitake",
    positione: "Design & MultiMedia",
  },
  {
    imagee: Vaibhav_Panchal,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Vaibhav Panchal",
    positione: "Finance",
  },
  {
    imagee: vaibhav_pujari,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Vaibhav Pujari",
    positione: "Management",
  },
  {
    imagee: Vansh_Bhatt,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Vansh Bhatt",
    positione: "Public Media Relations",
  },

  {
    imagee: Yash_Kale,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Yash Kale",
    positione: "Technical",
  },
  {
    imagee: Yogiraj_chaukhande,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Yogiraj Chaukhande",
    positione: "Technical",
  },
];

export default execom;
