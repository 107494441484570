import "../css/TsHomePage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import mishra from "../assets/images/speakers/mishra.jpeg";

export function SliderTs() {
  let isMobile = useMediaQuery({ query: "(max-width:1200px)" });
  let isSmallMobile = useMediaQuery({ query: "(max-width:645px)" });
  var settings = {
    dots: true,
    color: "white",
    infinite: true,
    speed: 500,
  };

  return (
    <>
      <Slider {...settings}>
        {/* Card 1 */}
        <div className="domainCard">
          <h1 className="domainName">Game Development</h1>
          <p className="domainInfo">
          Winner of government of India’s Toycathon 2021 | Co-Founder, GameNagri Studios | Speaker at Sumo Developer Conference’24
          </p>
          <div className="speakerInfo">
            <div className="imagePerson">
              <img className="imgPerson" src={mishra} alt=""></img>
            </div>
            <div className="InfoSpeaker">
              <h1 className="SpeakerName">Siddharth Mishra</h1>
              <h2 className="designationSpeaker">
                Programmer - Sumo Digital Ltd.
              </h2>
            </div>
          </div>
        </div>
        {/* Card 1 */}
        
        
      </Slider>
    </>
  );
}
export default SliderTs;
