import React from "react";
// import { SpeakerCardLeft } from "./components/SpeakerCard";
import { SpeakerCardRight } from "../components/SpeakerCard";
import speakersList from "../components/Speakers";
import PanelList from "../components/PanelList";
import "../css/Speakers.css";
import { useMediaQuery } from "react-responsive";
import { MobileSpeakerCard } from "../components/MobileSpeakerCard";
import TsNavbar from "../components/TsNavbar";
import ubisoft from "../assets/images/speakers/ubisoft-logo.png";
import { useNavigate } from "react-router-dom";


function SpeakerPage() {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 540px)",
  });

  const navigate = useNavigate(); // Initialize useNavigate for internal navigation

  // Function to handle Register Now button click
  const handleRegisterClick = () => {
    navigate("/"); // Navigate to EventRegistration page
  };

  return (
    <div className="Spbody">
      <TsNavbar />
      <h2 className="SpHeading">TECH TALK</h2>
      <p className="SpInfo">
        Get ready to embark on an exciting journey into the fascinating world of
        technology with our interactive session led by an enthusiastic speaker!
        Discover the latest advancements and trends in Game Development as our expert shares his knowledge and insights
        with you.{" "}
      </p>
      <div className="gradient1"></div>

      <div className="mappingContainer">
        {speakersList.map((sp, index) => {
          return (
            <div key={index}>
              {isMobileDevice ? (
                <MobileSpeakerCard
                  key={sp.id}
                  name={sp.name}
                  designation={sp.designation}
                  description={sp.description}
                  image={sp.image}
                  linkedIn={sp.linkedIn}
                  insta={sp.insta}
                  twitter={sp.twitter}
                />
              ) : (
                <SpeakerCardRight
                  key={sp.id}
                  name={sp.name}
                  designation={sp.designation}
                  description={sp.description}
                  image={sp.image}
                  linkedIn={sp.linkedIn}
                  insta={sp.insta}
                  twitter={sp.twitter}
                />
              )}
            </div>
            
          );
          
        })}
      </div>
      <div className="TechsummitSession1BtnContainer">
            {/* Replaced the anchor link with a button that uses handleRegisterClick */}
            <button className="TSS4button" onClick={handleRegisterClick} disabled >
              Register Now
            </button>
            </div>
    </div>
  );
}

export default SpeakerPage;
