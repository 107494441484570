import React from "react";
import "../css/ErrorPage/ErrorPage.css";
import errorImage from "../assets/images/errorPage/errorImage.png";

export default function ErrorPage() {
  return (
    <div className="errorContainer">
      <div className="wrap-404">
        <div className="EPlabel">Error</div>
        <div className="EPnumbers">
          <img className="errorImage" src={errorImage} alt="" />
        </div>
        <div className="EPtext">
          <p>Looks like you got lost... Or we trying to confuse you...</p>
          <p>
            Let us bring you at{" "}
            <a className="EPa" href="/">
              home
            </a>
            .
          </p>
        </div>
        <div className="EPsleep-walker">
          <div className="EPman">
            <div className="EPhead"></div>
            <div className="EPtorso">
              <div className="EParm-a"></div>
              <div className="EParm-b"></div>
            </div>
            <div className="EPlegs">
              <div className="EPleg-a"></div>
              <div className="EPleg-b"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
