import React from "react";
import "../css/aboutUs/Wholeteam.css";

function FacultyTeam(props) {
  const { imagec, positionc, namec } = props.data;

  return (
    <div className="coreteam facultyTeam">
      <div className="bgo">
        <img className="images" src={imagec} alt="" />
      </div>
      <div className="overlay"></div>
      <p className="AUname FacultyName">{namec}</p>
      <p className="AUposition">{positionc}</p>
    </div>
  );
}

export default FacultyTeam;
