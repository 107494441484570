import Aditya from "../assets/images/aboutUs/developers/Aditya_Mhaske.png";
import Gauri from "../assets/images/aboutUs/developers/Gauri_Choudhari.png";
import Gyaneshwari from "../assets/images/aboutUs/developers/Gyaneshwari_Patil.png";
import Kirti from "../assets/images/aboutUs/developers/kirti_Agarwal.png";
import Mrunmayee from "../assets/images/aboutUs/developers/Mrunmayee_Phadke.png";
import OmK from "../assets/images/aboutUs/developers/Om_Khode.png";
import Pranav from "../assets/images/aboutUs/developers/Pranav_Joshi.png";
import Riddhi from "../assets/images/aboutUs/developers/Riddhi_Halade.png";
import Sakshee from "../assets/images/aboutUs/developers/Sakshee_Agrawal.png";
import Sayee from "../assets/images/aboutUs/developers/Sayee_Zanzane.png";
import Tanmay from "../assets/images/aboutUs/developers/Tanmay_Mutalik.png";
import Tushar from "../assets/images/aboutUs/developers/Tushar_Nasery.png";

const developers = [
  {
    image: Kirti,
    linkedin: "https://www.linkedin.com/in/kirti-agarwal-410565154",
    git: "https://github.com/kirtii119",
    insta: "https://www.instagram.com/kirtii_119/",
    name: "Kirti Agarwal",
    position: "Chairperson 2022-2023",
  },
  {
    image: Tanmay,
    linkedin:
      "https://www.linkedin.com/in/tanmay-mutalik-b6658b18a/?originalSubdomain=in",
    git: "https://github.com/Tanmay19code",
    insta: "https://www.instagram.com/tanmaymutalik2002",
    name: "Tanmay Mutalik",
    position: "Web Project Head 2022-2023",
  },
  {
    image: OmK,
    linkedin: "https://www.linkedin.com/in/om-khode",
    git: "https://github.com/Om-Khode",
    insta: "https://www.instagram.com/om_khode/",
    name: "Om Khode",
    position: "Web Project Head 2023-2024",
  },
  {
    image: Mrunmayee,
    linkedin: "https://www.linkedin.com/in/mrunmayee-phadke-635060241/",
    git: "https://github.com/Mrunmayee-762004",
    insta: "https://www.instagram.com/mrunmayee764/",
    name: "Mrunmayee Phadke",
    position: "AI Project Head 2023-2024",
  },
  {
    image: Aditya,
    linkedin: "https://www.linkedin.com/in/aditya-mhaske-77a642223/",
    git: "https://github.com/MhaskeAditya42",
    insta: "https://www.instagram.com/adityamhaske42/",
    name: "Aditya Mhaske",
    position: "Multimedia Head 2023-2024",
  },
  {
    image: Gauri,
    linkedin: "https://www.linkedin.com/in/gauri-choudhari-305193231/",
    git: "https://github.com/Gauri-Choudhari",
    insta: "https://www.instagram.com/gauric_1412/",
    name: "Gauri Choudhari",
    position: "EXECOM 2022-2023",
  },
  {
    image: Gyaneshwari,
    linkedin: "https://www.linkedin.com/in/gyaneshwari58",
    git: "https://github.com/Gyaneshwari91",
    insta: "https://www.instagram.com/_gyanvi_58/",
    name: "Gyaneshwari Patil",
    position: "EXECOM 2022-2023",
  },
  {
    image: Pranav,
    linkedin: "https://www.linkedin.com/in/pranav-joshi-168298231/",
    git: "https://github.com/phoen1xsan",
    insta: "https://instagram.com/pranavj_01/",
    name: "Pranav Joshi",
    position: "Research Head 2023-2024",
  },

  {
    image: Riddhi,
    linkedin: "https://www.linkedin.com/in/riddhi-halade-a317b2231/",
    git: "https://github.com/riddhihalade",
    insta: "https://www.instagram.com/riddhihalade/",
    name: "Riddhi Halade",
    position: "EXECOM 2022-2023",
  },
  {
    image: Sakshee,
    linkedin: "https://www.linkedin.com/in/sakshee-agrawal-b600a4238/",
    git: "https://github.com/sakshee15",
    insta: "https://www.instagram.com/sakshee_agrawal_15/",
    name: "Sakshee Agrawal",
    position: "EXECOM 2022-2023",
  },
  {
    image: Sayee,
    linkedin: "https://www.linkedin.com/in/sayee-zanzane-714339228/",
    git: "https://github.com/SayeeZ",
    insta: "https://www.instagram.com/sayee_1512/",
    name: "Sayee Zanzane",
    position: "EXECOM 2022-2023",
  },
  {
    image: Tushar,
    linkedin: "https://www.linkedin.com/in/tushar-nasery",
    git: "https://github.com/tusharn85",
    insta: "https://www.instagram.com/_tusharnasery/ ",
    name: "Tushar Nasery",
    position: "Chairperson 2023-2024",
  },
];

export default developers;
