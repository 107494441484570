import React from "react";
import LoginUsingOtpForm from "../components/LoginUsingOtpForm";
import Particle from "../components/Particle";

export default function LoginUsingOtp() {
  return (
    <div className="signUp">
      <Particle />
      <LoginUsingOtpForm />
    </div>
  );
}
