import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SignUpInput from "./SignUpInput";
import "../css/signUp/SignUp.css";
import "../css/login/Login.css";
import "../css/signUp/SignUpMediaQuery.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Loader";

export default function LoginCard(props) {
  const { setLoggedIn } = props.props; //for setting the login state
  const [inputs, setInputs] = useState({
    //initial state of the form
    email: "",
    password: "",
  });

  const [loader, setLoader] = useState(false); //for displaying the loader

  const notifySuccess = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notify = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const navigate = useNavigate(); //for navigation

  const handleChange = (event) => {
    //function to handle the change in the input fields
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const maxLengthCheck = (object) => {
    //function to check the maximum length of the input fields
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    //function to handle the submit event
    e.preventDefault();
    const { email, password } = inputs;

    let body = JSON.stringify({
      //body of the request
      email,
      password,
    });

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        //post request to the backend
        "https://ieeevitpune.com/" + "user/loginwithPW",
        body,
        config
      );
      if (response.data.success === true) {
        //if the login is successful
        localStorage.setItem("token", response.data.data.authToken); //store the token in the local storage
        notifySuccess(response.data.msg); //display the success message .
        setLoggedIn(true); //set the login state to true
        navigate("/", { replace: true }); //navigate to the domain page
      } else {
        //if the login is unsuccessful
        notify(response.data.msg); //display the error message
        // console.log(response);
      }
    } catch (err) {
      notify(err.response.data.msg);
      // console.log(err);
    }
    setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="loginForm">
        <div className="SFtitle LFtitle">Login</div>
        <form onSubmit={handleSubmit} className="form">
          <SignUpInput
            name="email"
            labelName="Email Id"
            type="email"
            className="lemail"
            value={inputs?.email}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <SignUpInput
            name="password"
            labelName="Password"
            type="password"
            className="lpassword"
            value={inputs?.password}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <Link to="/forgotPassword" className="LIforgotPassword">
            Forgot Password?
          </Link>
          <div className="submitContainer">
            <button className="submit" type="submit">
              Login
            </button>
          </div>
          <div className="member">
            Don't have an account?{" "}
            <Link to="/signUp" className="SUlink">
              Sign up
            </Link>
          </div>
          <div className="verifyContainer">
            <Link to="/loginUsingOtp" className="SUlink">
              Login using OTP
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
