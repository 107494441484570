import React, { useEffect } from "react";
import ai_ml from "../assets/images/domain/ai_ml.png";
import app_dev from "../assets/images/domain/app_dev.png";
import blockchain from "../assets/images/domain/blockchain.png";
import coding_club from "../assets/images/domain/coding_club.png";
import cyber_security from "../assets/images/domain/cyber_security.png";
import iot from "../assets/images/domain/iot.png";
import web_dev from "../assets/images/domain/web_dev.png";
import research from "../assets/images/domain/research.png";

export default function DomainCircle(props) {
  // function rotateImageClockWise() {
  // var myImage = document.getElementById("circleContainer");
  // myImage.style.transform = `rotate(${45 * props.state.count}deg)`;
  // }

  // useEffect(() => {
  // rotateImageClockWise();
  // eslint-disable-next-line
  // }, [props.state.count]);

  return (
    <div id="circleContainer">
      <div className="srow1">
        <img
          src={ai_ml}
          className="item ai"
          // className={
          //   props.state.text === "AI - ML"
          //     ? "item ai center center1"
          //     : "item ai"
          // }
          alt=""
        />
        <img
          src={cyber_security}
          // className={
          //   props.state.text === "CYBER SECURITY"
          //     ? "item cyber center center2"
          //     : "item cyber"
          // }
          className="item cyber"
          alt=""
        />
      </div>
      <div className="srow2">
        <img
          src={web_dev}
          // className={
          //   props.state.text === "WEB DEV"
          //     ? "item web center center3"
          //     : "item web"
          // }
          className="item web"
          alt=""
        />
        <img
          src={coding_club}
          // className={
          //   props.state.text === "CODING CLUB"
          //     ? "item coding center center4"
          //     : "item coding"
          // }
          className="item coding"
          alt=""
        />
      </div>
      <div className="srow3">
        <img
          src={research}
          // className={
          //   props.state.text === "RESEARCH"
          //     ? "item research center center5"
          //     : "item research"
          // }
          // alt=""
          className="item research"
        />
        <img
          src={blockchain}
          // className={
          //   props.state.text === "BLOCKCHAIN"
          //     ? "item blockchain center center6"
          //     : "item blockchain"
          // }
          className="item blockchain"
          alt=""
        />
      </div>
      <div className="srow4">
        <img
          src={iot}
          // className={
          //   props.state.text === "IOT" ? "item iot center center7" : "item iot"
          // }
          className="item iot"
          alt=""
        />
        <img
          src={app_dev}
          // className={
          //   props.state.text === "APP DEV"
          //     ? "item app center center8"
          //     : "item app"
          // }
          className="item app"
          alt=""
        />
      </div>
    </div>
  );
}

DomainCircle.defaultProps = {
  state: {
    count: 0,
    text: "AI - ML",
  },
};
