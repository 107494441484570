import React from "react";
import "../css/developers/Developers.css";
import github_logo from "../assets/images/developers/github.svg";
import instagram_logo from "../assets/images/developers/instagram.svg";
import linkedin_logo from "../assets/images/developers/linkedin.svg";

export default function WebDevelopers({ data }) {
  return (
    <div className="DPcontainer">
      {data.map((data, index) => {
        return (
          <div key={index} className="DPcard">
            <div className="DPcontent">
              <div className="DPimgContainer">
                <img src={data.image} className="DPimg" alt="" />
              </div>
              <div className="DPcardContent">
                <h3 className="DPh3">
                  {data.name}
                  <br />
                  <span className="DPspan">{data.position}</span>
                </h3>
              </div>
              {/* <div className="DPquote">
                <p className="DPp">
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Obcaecati commodi, at ipsam laboriosam iste suscipit"
                </p>
              </div> */}
            </div>
            <div className="DPsci">
              <div className="DPli">
                <a
                  className="DPa"
                  href={data.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedin_logo} className="DPimg2" alt="" />
                </a>
              </div>
              <div className="DPli">
                <a
                  className="DPa"
                  href={data.insta}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram_logo} className="DPimg2" alt="" />
                </a>
              </div>
              <div className="DPli">
                <a
                  className="DPa"
                  href={data.git}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={github_logo} className="DPimg2" alt="" />
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
