import React from "react";
import DomainData from "../data/DomainData";

import dropDownArrow from "../assets/images/domain/dropDownArrow.png";

export default function DomainInfoMobile(props) {
  const { count, setCount, setRotate } = props;
  const data = DomainData;

  const NextBtnFunction = () => {
    setCount((prevCount) => (prevCount % 7) + 1);
    setRotate((prevRotate) => prevRotate + 1);
  };

  const PrevBtnFunction = () => {
    if (count === 0) setCount(7);
    else setCount((prevCount) => prevCount - 1);
    setRotate((prevRotate) => prevRotate - 1);
  };

  return (
    <div className="DomainMobileContentContainer">
      <button onClick={PrevBtnFunction} className="DMbtn DMprevBtn">
        <img className="DMbtnImg" src={dropDownArrow} alt="" />
      </button>
      <div className="DomainMobileCard">
        <h2 className="DomainNameTitle">{data[count]?.domain}</h2>
        <p className="DomainMobileInfo">{data[count]?.info}</p>
      </div>
      <button onClick={NextBtnFunction} className="DMbtn DMnextBtn">
        <img className="DMbtnImg" src={dropDownArrow} alt="" />
      </button>
    </div>
  );
}
