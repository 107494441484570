import React from "react";
import LoginCard from "../components/LoginCard";
import Particle from "../components/Particle";

export default function Login(props) {
  return (
    <div className="signUp">
      <Particle />
      <LoginCard props={props} />
    </div>
  );
}
