import Anushka from "../assets/images/aboutUs/core/Anushka_Khandelwal.png";
import Atharva from "../assets/images/aboutUs/core/Atharva_Ansingkar.png";
import Hritesh from "../assets/images/aboutUs/core/Hritesh_Maikap.png";
import Ishita from "../assets/images/aboutUs/core/Ishita_Jasuja.png";
import Nidhish from "../assets/images/aboutUs/core/Nidhish_Wakodikar.png";
import Nishant from "../assets/images/aboutUs/core/Nishant_Lanjewar.png";
import Priyal from "../assets/images/aboutUs/core/Priyal_Patange.png";
import Qusai from "../assets/images/aboutUs/core/Qusai_Shergardwala.png";
import Sarvesh from "../assets/images/aboutUs/core/Sarvesh_Bajare.png";
import Shreyas from "../assets/images/aboutUs/core/Shreyas_Nagarkar.png";
import Soham from "../assets/images/aboutUs/core/Soham_Nimale.png";
import Vaishnavi from "../assets/images/aboutUs/core/Vaishnavi_Singh.png";

const core = [
  {
    imagec: Sarvesh,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Sarvesh Bajare",
    positionc: "Chairperson",
  },
  {
    imagec: Ishita,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Ishita Jasuja",
    positionc: "Vice Chairperson",
  },
  {
    imagec: Anushka,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Anushka Khandelwal",
    positionc: "Joint-Secretary",
  },
  {
    imagec: Priyal,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Priyal Patange",
    positionc: "Joint-Secretary",
  },
  {
    imagec: Shreyas,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Shreyas Nagarkar",
    positionc: "Finance Head",
  },
  {
    imagec: Hritesh,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Hritesh Maikap",
    positionc: "Project Head",
  },
  {
    imagec: Atharva,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Atharva Ansingkar",
    positionc: "Project Head",
  },
  {
    imagec: Qusai,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Qusai Shergardwala",
    positionc: "Project Head",
  },
  {
    imagec: Nidhish,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Nidhish Wakodikar",
    positionc: "Coding Club Head",
  },
  {
    imagec: Nishant,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Nishant Lanjewar",
    positionc: "Coding Club Head",
  },
  {
    imagec: Soham,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Soham Nimale",
    positionc: "Research Head",
  },
  {
    imagec: Vaishnavi,
    linkedinc: "https://www.linkedin.com/",
    gitc: "https://github.com/",
    namec: "Vaishnavi Singh",
    positionc: "Multimedia Head",
  },
];

export default core;
