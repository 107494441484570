import React, { useState, useEffect } from "react";
import "../css/Timeline.css";
import SteppedLineTo from "react-lineto";

const Timeline = ({ day }) => {
  const day1 = [
    {
      title: "Verification and check-in",
      time: "4:00 PM",
      description:
        "Attendees will need to go through a verification and check-in process before the event begins.",
    },
    {
      title: "Inauguration",
      time: "4:30 PM",
      description:
        "The official opening ceremony of the Tech Summit will take place.",
    },
    {
      title: "Panel Discussion : UBISOFT - Game Development",
      time: "5:00 PM",
      description:
        "Join industry experts from Ubisoft as they discuss the latest trends and techniques in game development.",
    },
  ];

  const day2 = [
    {
      title: "Verification and check-in",
      time: "10:00 AM",
      description:
        "Attendees will need to go through a verification and check-in process before the event begins.",
    },
    {
      title: "Workshop - Digital Forensics Part 1",
      time: "10:30 AM",
      description:
        "Participants will learn the basics of digital forensics and how to collect and analyze digital evidence.",
    },
    {
      title: "Refreshment Break",
      time: "12:30 PM",
      description:
        "Take a short break and enjoy some refreshments before the event continues.",
    },
    {
      title: "Workshop - Digital Forensics Part 2",
      time: "1:00 PM",
      description:
        "This workshop builds upon the basics covered in Part 1, delving deeper into digital forensics with advanced techniques.",
    },
    {
      title: "Tech Talk :  IBM - Prashant Mestri - Quantum Computing",
      time: "4:00 PM",
      description:
        "Join Prashant Mestri from IBM as he discusses the fascinating world of quantum computing and its potential applications in various industries.",
    },
  ];

  const day3 = [
    {
      title: "Verification and check-in",
      time: "11:30 AM",
      description:
        "Attendees will need to go through a verification and check-in process before the event begins.",
    },
    {
      title: "Tech Talk : Janvry - Yash Bhalkar - AR VR",
      time: "12:00 PM",
      description:
        "Yash Bhalkar from Janvry will explore the exciting possibilities of Augmented Reality (AR) and Virtual Reality (VR) technologies and how they are changing the way we experience the world.",
    },
    {
      title: "Lunch Break",
      time: "1:00 PM",
      description:
        "Take a break and enjoy a delicious lunch before the event continues.",
    },
    {
      title: "Tech Talk : Ajey Sudame - Data Analytics",
      time: "2:00 PM",
      description:
        "Ajey Sudame will share insights into the world of Data Analytics, covering topics such as data visualization, machine learning, and predictive modeling.",
    },
    {
      title: "Activities and Tech Games",
      time: "3:00 PM",
      description:
        "Participate in a variety of tech games and activities that will put your skills to the test and help you connect with fellow attendees.",
    },
    {
      title: "Tech Talk : Rajesh Kumar Purushottam - AI in MedTech",
      time: "5:30 PM",
      description:
        "Rajesh Kumar Purushottam will discuss the role of artificial intelligence in transforming the medical technology industry.",
    },
    {
      title: "Merch",
      time: "7:00 PM",
      description:
        "Get your hands on some cool event merchandise to commemorate the event.",
    },
  ];

  const [event, setEvent] = useState(day1);

  const [lineCount, setLineCount] = useState(0);

  useEffect(() => {
    setEvent(day === 1 ? day1 : day === 2 ? day2 : day3);
    setLineCount(lineCount + 1);
  }, [day]);

  return (
    <div className="timeline">
      <div className="TLeventContainer">
        {event.map((event, index) => (
          <div className="TLevent" key={index}>
            <div className={`TLeventTime TLeventTimeDiv${index}`}>
              {event.time}
            </div>
            <div className="TLflexContainer">
              <div className="TLeventTitle">{event.title}</div>
              <div className="TLeventDescription">{event.description}</div>
            </div>
          </div>
        ))}
        {event.map((event, index) => (
          <div key={index}>
            <SteppedLineTo
              from={`TLeventTimeDiv${index}`}
              to={`TLeventTimeDiv${index + 1}`}
              orientation="v"
              delay={true}
              borderColor="#D7DBE0"
              borderStyle="solid"
              borderWidth={4}
              fromAnchor="bottom 130%"
              toAnchor="top -30%"
              className="timelineLine"
              key={`line-${lineCount}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
