import React from 'react'
import { useMediaQuery } from 'react-responsive'
import profile from "../assets/images/speakers/Speaker_prof.png";
import instagramSVG from "../assets/images/speakers/instagram.png";
import linkedinSVG from "../assets/images/speakers/linkedin.png";
import twitterSVG from "../assets/images/speakers/twitter.png";
import yash_bhalkar from "../assets/images/speakers/yash-bhalkar-4.jpg";


export default function NFCVR() {
    const isMobileDevice = useMediaQuery({
        query: "(max-width: 540px)",
    });
    
    return (
        <div className="Spbody Spbody1">
        <h2 className="SpHeading">Virtual Reality</h2>
        <p className="SpInfo">
        Are you ready to take a deep dive into the world of virtual reality? Join us for an exciting tech talk on VR, where we'll explore the limitless potential of this cutting-edge technology.
        </p>
            <div className="mappingContainer"></div>
            <div>
            {!isMobileDevice ? (
      <div className="speaker-card-right-container">
        <div className="name-holder-R">
          <p className="name-R">Yash Bhalkar</p>
          <p className="designation-R">CEO, Janvry Studio, Immersive Tech (XR) Expert, Founder, Tedx Speaker</p>
        </div>
        <div className="bottom-part-R">
          <div className="profile-picture-div-R">
            <img className="profile-image-R" src={yash_bhalkar} alt="" />
          </div>
          <div className="right-part-R">
            <div className="description-R">Yash Bhalkar is a highly accomplished entrepreneur and immersive technology expert, widely recognized for creating unique, professional, and innovative virtual reality applications. As the founder of Janvry Studio, Yash is dedicated to advancing the field of the metaverse and web 3.0 through cutting-edge immersive technology solutions. He also has a wealth of experience in game development, AR/VR/XR development, project management, UI/UX and mentorship.</div>
            <div className="icons-R">
              <a href="https://www.linkedin.com/in/yashbhalkar/" target="_blank" rel="noreferrer">
                <img src={linkedinSVG} alt="" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                <img src={instagramSVG} alt="" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
                <img src={twitterSVG} alt="" />
              </a>
            </div>
          </div>
        </div>
                    </div>
                ) : (
                    <div className="Parent_div">
                    <div className="img_info">
                      <div className="Picture_div">
                        <img className="profile-image" src={yash_bhalkar} alt="" />
                      </div>
                      <div className="Designation_div">
                        <p className="name">Yash Bhalkar</p>
                        <p className="designation">CEO, Janvry Studio, Immersive Tech (XR) Expert, Founder, Tedx Speaker</p>
                      </div>
                    </div>
                    <div className="description">Yash Bhalkar is a highly accomplished entrepreneur and immersive technology expert, widely recognized for creating unique, professional, and innovative virtual reality applications. As the founder of Janvry Studio, Yash is dedicated to advancing the field of the metaverse and web 3.0 through cutting-edge immersive technology solutions. He also has a wealth of experience in game development, AR/VR/XR development, project management, UI/UX and mentorship.</div>
              
                    <div className="Icons_div">
                      <a href="https://www.linkedin.com/in/yashbhalkar/"  target="_blank" rel="noreferrer">
                        <img className="IndivIcons" src={linkedinSVG} alt="" />
                      </a>
                      <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                        <img className="IndivIcons" src={instagramSVG} alt="" />
                      </a>
                      <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
                        <img className="IndivIcons" src={twitterSVG} alt="" />
                      </a>
                    </div>
                        </div>
                )}
      </div>
      </div>
  )
}
