import React from "react";
import "../css/Expo.css";
import expo_img from "../assets/images/expo/expo_img.png";
// import company_img from "../assets/images/expo/company_img.png";
import TsNavbar from "../components/TsNavbar";
import data from "../../data/TsExpo";

export default function Expo() {
  // const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <div className="expo">
      <TsNavbar />
      <div>
        <div className="expoTitle">IEEE EXPO</div>
        <div className="expoDesc">
          The IEEE Expo is an event where various companies can showcase their
          products. It provides an excellent opportunity for businesses to
          exhibit their latest innovations and technologies and attract a wider
          audience. Some of the main and featured technologies are: Robotics,
          IoT and AR/VR.
        </div>
        <div className="expoImgContainer">
          <img className="expoImg" src={expo_img} alt="" />
        </div>
      </div>
      <div>
        <div className="expoCompanyContainer">
          {data.map((item) => (
            <div key={item.id} className="expoCompany">
              <img className="companyImg" src={item.company_img} alt="" />
              <p className="companyTitle">
                <b>{item.company_name}</b>
              </p>
              <p className="expoDomainName">{item.domain_name}</p>
              <p className="companyDesc">{item.domain_info}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
