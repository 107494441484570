import yash_bhalkar from "../assets/images/speakers/yash-bhalkar-4.jpg";
import mishra2 from "../assets/images/speakers/mishra2.jpg";

const speakersList = [
  {
    id: 1,
    name: "Siddharth Mishra",
    description: `Siddharth Mishra is the co-founder and lead programmer at GameNagri Studios, where he drives innovation in game development with his passion for creating immersive digital experiences. He is also a Programmer at Sumo Digital and a Game Software Engineer at Gamestacy, showcasing his extensive expertise in game design, development, and software engineering.`,
    image: mishra2 ,
    designation:
      "Co-Founder, GameNagri Studios | Speaker at Sumo Developer Conference’24",
    linkedIn: "https://www.linkedin.com/in/siddharth-mishraa/",
    insta: "https://www.instagram.com/siddharthmishrapvt/",
    twitter: "https://www.twitter.com",
  },

];

export default speakersList;
