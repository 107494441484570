import React from "react";
import "../css/TsHomePage.css";
import SliderTs from "../components/SliderTs";

import CompanyCards from "../components/CompanyCards";
import SponserSlider from "../components/SponserSlider";
import Agenda from "../components/Agenda";
import { Link } from "react-router-dom";
import TsNavbar from "../components/TsNavbar";
import workshopImage from "../assets/images/homePage/workshop_image.jpg";
import ideathonImage from "../assets/images/homePage/ideathonImage2.png";
import CSI from "../assets/images/workshop/CSI.png";
import uptiq from "../assets/images/ideathon/UptiqLogoCropped.png";
import TSPanel from "../components/TSPanel";

export default function TsHomePage() {


  
  return (
    <>
      
      

        <TsNavbar />
        <div className="TechsummitSession1Container">
          <div className="TechsummitSession1">
            <div className="TechsummitSession1Heading">IEEE MONTH</div>
            <div className="TechsummitSession1SubHeading">
              <ul>
                <li>
                  A great <b className="TSS1b">speaker</b> line-up ready to talk about <b className="TSS1b">Game Development</b> 🎮
                  and how you can get started in this exciting field.
                </li>
                <li>
                  An immersive experience of attending <b className="TSS1b">Research Sessions.</b>{" "}
                  🕵️
                </li>
                <li>
                  A <b className="TSS1b">UI/UX Hackathon</b> where you can showcase your creativity and problem-solving skills.
                </li>
                <li>
                  Exciting <b className="TSS1b">Games</b> 🎮 created by our own team members
                  for you to <b className="TSS1b">play and enjoy.</b>
                </li>
                <li>
                  A display of <b className="TSS1b">IEEE Projects</b>  where you can see firsthand 
                  the innovative work our members are doing.
                </li>
                <li>
                Get to know us more understand what <b className="TSS1b">IEEE</b> is all about and 
                the impact we’ve made over the past <b className="TSS1b">25 years.</b>
                </li>
                <li>
                Explore the domains of{" "}
                  <b className="TSS1b"> App Development, Web Technologies, AI,</b>   and more with us.
                </li>
              </ul>
            </div>
            
          </div>
        </div>
        <div className="line"></div>
        <div className="headingSpeakers">TECH TALK</div>
        <div className="subHeading">
          Join us for this event featuring the exciting realm of Game Dev. Get to know the domain knowledge, experience, and insights.
        </div>

        {/* Domains Card Slider  */}
        <div className="domainSlider">
          <SliderTs />
        </div>
        <div className="TechsummitSession3BtnContainer">
          <Link to="TechTalk">
            <button className="TSS3button">See More</button>
          </Link>
        </div>

            
        {/* <div className="line"></div>
        <TSPanel />
        <div className="line"></div> */}

        

        <div className="line"></div>
        <div className="TechsummitSession3Container">
          <div className="TechsummitSession3">
            <div className="TSWflexConainer2">
              {/* <img src={uptiq} className="uptiqLogo" alt="" />
              <div className="uptiqPresents">Presents</div> */}
              <div className="TechsummitSession3Heading">HACKATHON</div>
            </div>
            <div className="TechsummitSession3SubHeading">
              <p className="TSS3p">
              Welcome to the EcoInnovate Hackathon!
Join us for the EcoInnovate Hackathon, a dynamic 24-hour event focused on harnessing the power of UI/UX design to combat climate change and address pressing environmental challenges. This hackathon invites passionate designers and innovators to create impactful, user-centered solutions that promote sustainability and environmental awareness.

              </p>
              <img src={ideathonImage} className="TSS3image" alt="" />
            </div>
            <div className="TechsummitSession3BtnContainer">
              <Link to="Hackathon">
                <button className="TSS3button">See More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="line"></div>

        <div className="TechsummitSession2Container">
          <div className="TechsummitSession2">
            <div className="TSWflexConainer1">
              <div className="TechsummitSession2Heading">RESEARCH SESSIONS</div>
            </div>
            <div className="TechsummitSession2SubHeading">
              <img src={workshopImage} className="TSS2image" alt="" />
              <p className="TSS2p">
              The IEEE Research Sessions, scheduled from the 20th to the 24th, capture the essence of research by guiding students through key stages—from design thinking and innovation to writing research papers. The five sessions explore how to tackle problems, navigate conferences and journals, and transform projects into formal research documents.
              </p>
            </div>
            <div className="TechsummitSession2BtnContainer">
              <Link to="/IEEEMonth/ResearchSessions">
                <button className="TSS2button">See More</button>
              </Link>
            </div>
          </div>
        </div>


        {/* <div className="heading1">IEEE EXPO</div>

        <p className="subHeading1">
          Join us at the exposition featuring numerous companies showcasing
          their products as well as latest innovations and technologies.{" "}
        </p>
        <CompanyCards />

        <div className="TechsummitSession3BtnContainer">
          <Link to="IEEEMonth/Expo">
            <button className="TSS3button">See More</button>
          </Link>
        </div>
        <div className="line"></div>
        <div>
          <Agenda />
        </div> */}
        {/* The Agenda  */}
        {/* <div className="line"></div> */}
        {/* Our Sponsers  */}
        {/* <h1 className="heading5">OUR SPONSORS</h1> */}
        {/* <p className="subDetailsSponsers">
          Thanks to our partners for sponsoring this event!
        </p>
        <div className="sponsSlider">
          <SponserSlider /> 
        </div>
        {/* Our Sponsers */}
        {/* <div className="line"></div> */}
        {/* The Venue  */}
        
        {/* <h1 className="heading6">VENUE</h1>
        <div className="venueContent">
          <div className="mapsImage">
            <iframe
              title="Map of Vishwakarma Institute of Technology"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.442128793462!2d73.86601501484182!3d18.46362188744226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ea950f616219%3A0x321bdae2cea9f064!2sVishwakarma%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1677335727696!5m2!1sen!2sin"
              width="585"
              height="385"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
          <p className="collegeAddress">
            Vishwakarma Institute of Technology at 666, Upper Indiranagar,
            Bibwewadi, Pune - 411 037.
          </p>
        </div> */}
      
    </>
  );
}
