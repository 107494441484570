import React from "react";
import { useMediaQuery } from "react-responsive";
import profile from "../assets/images/speakers/Speaker_prof.png";
import instagramSVG from "../assets/images/speakers/instagram.png";
import linkedinSVG from "../assets/images/speakers/linkedin.png";
import twitterSVG from "../assets/images/speakers/twitter.png";
import ajay_sudame from "../assets/images/speakers/Ajay-Sudame.jpeg";


export default function NFCQR() {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 540px)",
  });

  return (
    <div className="Spbody Spbody1">
      <h2 className="SpHeading">Data Analytics</h2>
      <p className="SpInfo">
      Join us for a tech talk on data analytics, where we'll explore the tools and techniques that are transforming data into actionable insights. 
      </p>
      <div className="mappingContainer"></div>
      <div>
        {!isMobileDevice ? (
          <div className="speaker-card-right-container">
            <div className="name-holder-R">
              <p className="name-R">Ajey Sudame</p>
              <p className="designation-R">Executive Director at EY</p>
            </div>
            <div className="bottom-part-R">
              <div className="profile-picture-div-R">
                <img className="profile-image-R" src={ajay_sudame} alt="" />
              </div>
              <div className="right-part-R">
                <div className="description-R">
                Ajey Sudame is a senior Data and Analytics Professional with over 26 years of industry experience. He advises Fortune 500 clients on data strategy and transformational projects. He has global experience as a data architect, thought leader on cloud data ecosystem and has led teams of data and analytics professionals.
                </div>
                <div className="icons-R">
                  <a
                    href="https://www.linkedin.com/in/ajey-sudame-3970424"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedinSVG} alt="" />
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagramSVG} alt="" />
                  </a>
                  <a
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitterSVG} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="Parent_div">
            <div className="img_info">
              <div className="Picture_div">
                <img className="profile-image" src={ajay_sudame} alt="" />
              </div>
              <div className="Designation_div">
                <p className="name">Ajey Sudame</p>
                <p className="designation">Executive Director at EY</p>
              </div>
            </div>
            <div className="description">
              Ajey Sudame is a senior Data and Analytics Professional with over 26 years of industry experience. He advises Fortune 500 clients on data strategy and transformational projects. He has global experience as a data architect, thought leader on cloud data ecosystem and has led teams of data and analytics professionals.
            </div>

            <div className="Icons_div">
              <a
                href="https://www.linkedin.com/in/ajey-sudame-3970424"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={linkedinSVG} alt="" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={instagramSVG} alt="" />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={twitterSVG} alt="" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
