import React from "react";
import loader from "../assets/images/loader/loader-gif.gif";
import "../css/loader/Loader.css";

export const Loader = () => {
  return (
    <div className="loader-container">
      <img src={loader} alt="loader-component" />
    </div>
  );
};
