import DroneLogo from "../TechSummit/assets/images/expo/DroneLogo.png";
import ElectricVehicleLogo from "../TechSummit/assets/images/expo/ElectricVehicleLogo.png";
import ThreeDPrinting1 from "../TechSummit/assets/images/expo/3dPrinting1.png";
import ThreeDPrinting2 from "../TechSummit/assets/images/expo/3dPrinting2.jpg";
import AASELogo from "../TechSummit/assets/images/expo/AASELogo.jpg";
import animationLogo from "../TechSummit/assets/images/expo/animationLogo.png";
import gameDevLogo from "../TechSummit/assets/images/expo/gameDevLogo.webp";
import roboticsLogo from "../TechSummit/assets/images/expo/roboticsLogo.png";

const expoData = [
  {
    id: 1,
    company_img: ElectricVehicleLogo,
    company_name: "Bounce Infinity",
    domain_name: "Electric Vehicles",
    domain_info:
      "Join the green revolution and experience the future of transportation with electric vehicles. Say goodbye to gas stations and hello to clean, sustainable energy that's good for the environment and your wallet!",
  },

  {
    id: 2,
    company_img: DroneLogo,
    company_name: "Known Aero",
    domain_name: "Drone Technology",
    domain_info:
      "Take to the skies with the latest advancements in drone technology. Explore the world of UAVs and see how they're revolutionizing industries from agriculture to entertainment.",
  },

  {
    id: 3,
    company_img: ThreeDPrinting1,
    company_name: "Geoclid",
    domain_name: "3D Printing",
    domain_info:
      "Experience the power of creation with 3D printing - the technology that's transforming the way we design and manufacture products.",
  },
  {
    id: 4,
    company_img: ThreeDPrinting2,
    company_name: "Techybuilds",
    domain_name: "3D Printing",
    domain_info:
      "Unlock your imagination with 3D printing - the technology that lets you turn your wildest ideas into reality.",
  },
  {
    id: 5,
    company_img: AASELogo,
    company_name: "AXSX",
    domain_name: "Aeronautics and Space Exploration",
    domain_info:
      "Journey to the final frontier with aeronautics and space exploration - the fields that are expanding our understanding of the universe and pushing the boundaries of human potential.",
  },
  {
    id: 6,
    company_img: animationLogo,
    company_name: "Arena Animations",
    domain_name: "Animation",
    domain_info:
      "Unlock your creativity and bring characters to life with animation - the art form that combines technology and artistry to create stunning visuals and captivating stories.",
  },
  {
    id: 7,
    company_img: gameDevLogo,
    company_name: "Cricinshots",
    domain_name: "Game dev",
    domain_info:
      "Bring your imagination to life with game development - the field that merges art, technology, and storytelling to create unforgettable interactive experiences.",
  },
  {
    id: 8,
    company_img: roboticsLogo,
    company_name: "Rigbetel Labs",
    domain_name: "Robotics",
    domain_info:
      "Experience the future of automation with robotics - the field that's transforming industries and revolutionizing the way we live and work.",
  },
];

export default expoData;
