import React from "react";
import "../css/signUp/SignUp.css";

export default function SignUpInput(props) {
  let {
    name,
    labelName,
    type,
    value,
    className,
    onChange,
    onKeyDown,
    maxLength,
    maxLengthCheck,
  } = props;

  return (
    <div className={`signUpInput ${className}`}>
      <label htmlFor={labelName} className="SItitle">
        {labelName}: *
      </label>
      <br />
      <input
        type={type}
        name={name}
        className={`${className}`}
        value={value}
        onKeyDown={onKeyDown}
        autoComplete="on"
        onChange={onChange}
        maxLength={maxLength}
        onInput={maxLengthCheck}
        required={true}
      />
    </div>
  );
}

SignUpInput.defaultProps = {
  type: "text",
  maxLengthCheck: () => {},
  maxLength: "50",
};
