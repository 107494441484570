import bhatlawande from "../assets/images/aboutUs/Faculty/bhatlawande.png";
import jabade from "../assets/images/aboutUs/Faculty/vaishali.png";

const faculty = [
  {
    imagec: bhatlawande,
    namec: "Dr. Shripad Bhatlawande",
    positionc: "Branch Mentor",
  },
  {
    imagec: jabade,
    namec: "Dr. Vaishali Jabade",
    positionc: "Branch Counselor",
  },
];

export default faculty;
