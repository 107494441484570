import React from "react";
// import Blogs from "./components/Blogs";
import Vision from "../components/Vision";
import FacultyTeam from "../components/FacultyTeam";
import Team from "../components/Team";
import Team1 from "../components/Team1";
import Coreteam from "../components/Coreteam";
import Execomteam from "../components/Execomteam";
// import "../AboutUs.css";
import "../css/aboutUs/AboutUs.css";
import "../css/aboutUs/Team.css";
import Execomteamdata from "../components/Execomteamdata";
import Coreteamdata from "../components/Coreteamdata";
import Facultyteamdata from "../components/FacultyData";

const execom = Execomteamdata;
const core = Coreteamdata;
const faculty = Facultyteamdata;

const AboutUs = () => {
  return (
    <div className="trial">
      <Vision />
      {/* <Blogs /> */}
      <div className="team">
        <p className="heading-3">
          <b className="heading-1b"> MEET THE TEAM</b>
        </p>
      </div>
      <div className="coreTrial facultyTrail">
        {faculty.map((element, index) => {
          return <FacultyTeam key={index} data={element} />;
        })}
      </div>
      <div className="team">
        <p className="core2022-23">
          <b> CORE 2024-2025</b>
        </p>
      </div>
      <div className="coreTrial">
        {core.map((element, index) => {
          return <Coreteam key={index} data={element} />;
        })}
      </div>
      <div className="team">
        <p className="execom2022-23">
          <b> EXECOM 2024-2025</b>
        </p>
      </div>
      <div className="execomTrial">
        {execom.map((element, index) => {
          return <Execomteam key={index} data1={element} />;
        })}
      </div>
    </div>
  );
};

export default AboutUs;
