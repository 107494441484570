const DomainData = [
  {
    domain: "AI - ML",
    info: "IEEE is absolutely aware of the rising significance of Al. Owing to its numerous applications, the club has even uploaded its own YouTube playlists of various Al based mini projects. In a recent workshop based on Neural Networks, the attendees were also taught to build a traffic recognition system right from scratch",
  },
  {
    domain: "App Dev",
    info: "Wanna engage well with your audience? Apps are one of the best ways to keep your audience engaged and committed. We also have our own master app dev team who are all-set to launch our very own app. Some of our previous exemplars in this field include a project on developing a Stock Inventory management app for APMC.",
  },
  {
    domain: "Blockchain",
    info: "With its decentralised nature, blockchain can lead us to better opportunities and benefit the businesses with enhanced security and transparency. This technology has evolved greatly in all possible sectors. IEEE had arranged an insightful workshop in which we covered everything between cryptocurrency to building Smart Contracts.",
  },
  {
    domain: "Coding",
    info: "One of our blockbusters, the coding club consists of all the enthusiastic programming geeks. The like-minded peeps meet twice a week in sessions organized exclusively for IEEE members. The instructors focus on helping you ace high profile questions solved from sites like hacker-rank, along with various fun activities and competitions.",
  },
  {
    domain: "Cyber Security",
    info: "We live in the digital era. As a result, several frauds and malicious attacks have been documented. Cybersecurity is required to preserve the internet era and It protects you from cybercriminals. IEEE organises influential webinars and workshops to help the attendees understand more about cyber security",
  },
  {
    domain: "IoT",
    info: `Internet-of-Things (IoT) gadgets are all around us, continually transferring data and "talking" to one another. Virtual assistants, "smart" electronics, and wearable health trackers are all examples of IoT devices that we encounter on a daily basis. Each Internet-of- Things device collects data in real time and transmits it to us in order to make our lives more efficient.`,
  },
  {
    domain: "Research",
    info: "IEEE is a global community of technical professionals. The IEEE Research Committee is a group of IEEE members who are interested in research and development. The committee is responsible for organizing research-related activities and events for IEEE members. The committee also provides a platform for members to share their research ideas and findings with other members.",
  },
  {
    domain: "WEB DEV",
    info: "IEEE VIT SB Pune actively indulges in one of the most lucrative & fast- growing technologies - web development. Few of our previous triumphs on this domain include the JavaScript workshop and Avenir - the frontend web dev competition, to name a few. The club also has its own team of web experts who undertake various industrial web dev projects.",
  },
];

export default DomainData;
