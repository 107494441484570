import React, { useState } from "react";
import "../css/homePage/DomainMobile.css";
// import Fade from "react-reveal/Fade";

import DomainInfoMobile from "./DomainInfoMobile";
import DomainCircleMobile from "./DomainCircleMobile";

export default function DomainMobile() {
  const [count, setCount] = useState(0);
  const [rotate, setRotate] = useState(0);

  return (
    <div className="DomainMobileContainer">
      {/* <Fade bottom> */}
      <h1 className="DMtitle">Domain</h1>
      <div className="DomainMobileCircle">
        <DomainCircleMobile rotate={rotate} />
      </div>
      <DomainInfoMobile
        count={count}
        setRotate={setRotate}
        setCount={setCount}
      />
      {/* </Fade> */}
    </div>
  );
}
