import React from "react";
import { useMediaQuery } from "react-responsive";
import profile from "../assets/images/speakers/Speaker_prof.png";
import instagramSVG from "../assets/images/speakers/instagram.png";
import linkedinSVG from "../assets/images/speakers/linkedin.png";
import twitterSVG from "../assets/images/speakers/twitter.png";
import prashant_mestri from "../assets/images/speakers/prashant-mestri.jpg";

export default function NFCQR() {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 540px)",
  });

  return (
    <div className="Spbody Spbody1">
      <h2 className="SpHeading">Quantum Computing</h2>
      <p className="SpInfo">
        Join us for a deep dive into the world of quantum computing, where our
        expert speakers will guide you through the technical aspects of this
        cutting-edge technology.
      </p>
      <div className="mappingContainer"></div>
      <div>
        {!isMobileDevice ? (
          <div className="speaker-card-right-container">
            <div className="name-holder-R">
              <p className="name-R">Prashant Mestri</p>
              <p className="designation-R">Solutions Architect at IBM</p>
            </div>
            <div className="bottom-part-R">
              <div className="profile-picture-div-R">
                <img className="profile-image-R" src={prashant_mestri} alt="" />
              </div>
              <div className="right-part-R">
                <div className="description-R">
                  25 years of experience in solution architecture, software
                  product development, application development, cloud solutions,
                  and digital security.
                </div>
                <div className="icons-R">
                  <a
                    href="https://www.linkedin.com/in/prashant-mestri/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedinSVG} alt="" />
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagramSVG} alt="" />
                  </a>
                  <a
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitterSVG} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="Parent_div">
            <div className="img_info">
              <div className="Picture_div">
                <img className="profile-image" src={prashant_mestri} alt="" />
              </div>
              <div className="Designation_div">
                <p className="name">Prashant Mestri</p>
                <p className="designation">Solutions Architect at IBM</p>
              </div>
            </div>
            <div className="description">
              25 years of experience in solution architecture, software product
              development, application development, cloud solutions, and
              digital security.
            </div>

            <div className="Icons_div">
              <a
                href="https://www.linkedin.com/in/prashant-mestri/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={linkedinSVG} alt="" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={instagramSVG} alt="" />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={twitterSVG} alt="" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
