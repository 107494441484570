import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function TsNavbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const navbar = document.querySelector(".navbar");
      const topOfNavbar = navbar.offsetTop;

      if (currentScrollPos > prevScrollPos) {
        // User is scrolling down
        if (currentScrollPos >= topOfNavbar) {
          setIsSticky(true);
        }
      } else {
        // User is scrolling up
        if (currentScrollPos <= topOfNavbar) {
          setIsSticky(false);
        }
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className="Navbar">
      <nav className={`navbar ${isSticky ? "sticky" : ""}`}>
        <ul>
          <Link to="/" className="TsNavbarHome">
            Home
          </Link>
          <Link to="/IEEEMonth" className="TsNavbarHome">
            IEEE Month
          </Link>
          <Link to="/IEEEMonth/TechTalk" className="TsNavbarHome">
            TechTalk
          </Link>
          {/* <Link to="IEEEMonth/Expo" className="TsNavbarHome">
            IEEE Expo
          </Link> */}
          
          <Link to="/IEEEMonth/Hackathon" className="TsNavbarHome">
            Hackathon
          </Link>
          <Link to="/IEEEMonth/ResearchSessions" className="TsNavbarHome">
            Research Sessions
          </Link>
          {/* <Link to="IEEEMonth/Expo" className="TsNavbarHome">
            IEEE Expo
          </Link> */}
          {/* <li>
            <a
              className="TsNavbarHome"
              href="https://bit.ly/IEEE_TechSummit23"
              target="_blank"
              rel="noreferrer"
            >
              Register
            </a>
          </li> */}
        </ul>
        <div className="lineNav"></div>
      </nav>
    </div>
  );
}
