import React from "react";
import "../css/TsHomePage.css";
import "../css/TSpanel.css";
import gameDev from "../assets/images/speakers/game_dev2.avif";
import ubisoft from "../assets/images/speakers/ubisoft-logo.png";
import lakkewar from "../assets/images/speakers/lakkewar.png";
import chavan from "../assets/images/speakers/chavan.png";
import damodhare from "../assets/images/speakers/damodhare.png";
import { Link } from "react-router-dom";

export default function TSPanel() {
  return (
    <div className="PanelDicussionContainer TSHPPDConatiner TSHPPDConatiner1">
      <h2 className="SpHeading PanelDicussionTitle">PANEL DISCUSSION</h2>
      <p className="SpInfo panelInfo">
        Presented by <img src={ubisoft} className="ubisoftLogo" alt="" />
          </p>
          <p className="SpInfo">
          Game development is an art and a science, requiring creativity, technical skill, and a deep understanding of player psychology. Join us for a panel discussion with some of the brightest minds in game development from Ubisoft, one of the world's leading game development companies.
      </p>
      <div className="PanelDicussionSubContainer">
        <div className="PanelDicussionSubContainer1">
          <div className="PanelDicussionSubContainer1Img">
            <img src={lakkewar} className="PDSCimg" alt="" />
          </div>
          <div className="PanelDicussionSubContainer1Info">
            <h3 className="PanelDicussionSubContainer1InfoName">
              Sumit Lakkewar
            </h3>
            <p className="PanelDicussionSubContainer1InfoDesignation">
              Technical Manager at Ubisoft Pune
            </p>
          </div>
        </div>
        <div className="PanelDicussionSubContainer1">
          <div className="PanelDicussionSubContainer1Img">
            <img src={damodhare} className="PDSCimg" alt="" />
          </div>
          <div className="PanelDicussionSubContainer1Info">
            <h3 className="PanelDicussionSubContainer1InfoName">
              Avinash Damodhare
            </h3>
            <p className="PanelDicussionSubContainer1InfoDesignation">
              Associate Technical Director at Ubisoft Pune
            </p>
          </div>
        </div>
        <div className="PanelDicussionSubContainer1">
          <div className="PanelDicussionSubContainer1Img">
            <img src={chavan} className="PDSCimg" alt="" />
          </div>
          <div className="PanelDicussionSubContainer1Info">
            <h3 className="PanelDicussionSubContainer1InfoName">
              Ashish Chavan
            </h3>
            <p className="PanelDicussionSubContainer1InfoDesignation">
              Quality Control Program Manager at Ubisoft Pune
            </p>
          </div>
        </div>
      </div>
      <div className="TechsummitSession3BtnContainer">
        <Link to="IEEEMonth/TechTalk">
          <button className="TSS3button">See More</button>
        </Link>
      </div>
    </div>
  );
}
