import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/signUp/SignUp.css";
import "../css/login/Login.css";
import "../css/signUp/SignUpMediaQuery.css";
import SignUpInput from "./SignUpInput";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Loader";

export default function LoginUsingOtpForm() {
  const [inputs, setInputs] = useState({
    //initial state of the form
    email: "",
    otp: "",
  });

  const [loader, setLoader] = useState(false); //for displaying the loader

  const [clicked, setClicked] = useState(false); //state to check if the button is clicked or not

  const notifySuccess = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notify = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const navigate = useNavigate(); //for navigation

  const handleChange = (event) => {
    //function to handle the change in the input fields
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const maxLengthCheck = (object) => {
    //function to check the maximum length of the input fields
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true); //display the loader
    //function to handle the submit event
    e.preventDefault();
    const { email, otp } = inputs;

    // console.log(email, otp);

    if (!clicked) {
      //if the button is not clicked
      if (email === "") {
        //if the email field is empty
        notify("Please enter your email id");
        return;
      }
      const body = JSON.stringify({ email });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.post(
          //post request to the backend
          "https://ieeevitpune.com/" + "user/loginwithOTP",
          body,
          config
        );
        if (response.data.success === true) {
          //if the login is successful
          notifySuccess(response.data.msg); //display the success message
          setClicked(true); //set the button to clicked
        } else {
          //if the login is unsuccessful
          notify(response.data.msg); //display the error message
        }
      } catch (err) {
        notify(err.response.data.msg);
      }
    } else {
      //if the button is clicked
      if (otp === "") {
        //if the otp field is empty
        notify("Please enter the OTP");
        return;
      }
      const body = JSON.stringify({ email, otp });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.post(
          //post request to the backend
          "https://ieeevitpune.com/" + "user/verifyLoginOtp",
          body,
          config
        );
        if (response.data.success === true) {
          //if the login is successful
          localStorage.setItem("token", response.data.data.authToken); //store the token in the local storage
          notifySuccess(response.data.msg); //display the success message
          navigate("/", { replace: true }); //navigate to the domain page
        } else {
          //if the login is unsuccessful
          notify(response.data.msg); //display the error message
        }
      } catch (err) {
        notify(err.response.data.msg);
      }
    }
    setLoader(false); //hide the loader
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <div className="loginForm">
        <div className="SFtitle">Login</div>
        <form onSubmit={handleSubmit} className="form">
          <SignUpInput
            name={!clicked ? "email" : "otp"}
            labelName={!clicked ? "Email Id" : "Enter OTP"}
            type={!clicked ? "email" : "text"}
            className="lemail"
            value={!clicked ? inputs?.email : inputs?.otp}
            onChange={handleChange}
            maxLengthCheck={maxLengthCheck}
          />
          <div className="submitContainer">
            <button className="submit" type="submit">
              {!clicked ? "Get OTP" : "Login"}
            </button>
          </div>
          <div className="member">
            Don't have an account?{" "}
            <Link to="/signUp" className="SUlink">
              Sign up
            </Link>
          </div>
          <div className="verifyContainer">
            <Link to="/Login" className="SUlink">
              Login using Password
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
