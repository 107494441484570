import React from "react";
import profile from "../assets/images/speakers/Speaker_prof.png";
import instagramSVG from "../assets/images/speakers/instagram.png";
import linkedinSVG from "../assets/images/speakers/linkedin.png";
import twitterSVG from "../assets/images/speakers/twitter.png";
import "../css/MobileSpeakers.css";

const MobileSpeakerCard = (props) => {
  return (
    <div className="Parent_div">
      <div className="img_info">
        <div className="Picture_div">
          <img className="profile-image" src={props.image} alt="" />
        </div>
        <div className="Designation_div">
          <p className="name">{props.name}</p>
          <p className="designation">{props.designation}</p>
        </div>
      </div>
      <div className="description">{props.description}</div>

      <div className="Icons_div">
        <a href={props.linkedIn} target="_blank" rel="noreferrer">
          <img className="IndivIcons" src={linkedinSVG} alt="" />
        </a>
        <a href={props.insta} target="_blank" rel="noreferrer">
          <img className="IndivIcons" src={instagramSVG} alt="" />
        </a>
        <a href={props.twitter} target="_blank" rel="noreferrer">
          <img className="IndivIcons" src={twitterSVG} alt="" />
        </a>
      </div>
    </div>
  );
};

export { MobileSpeakerCard };
