import React from "react";
import { useMediaQuery } from "react-responsive";
import profile from "../assets/images/speakers/Speaker_prof.png";
import instagramSVG from "../assets/images/speakers/instagram.png";
import linkedinSVG from "../assets/images/speakers/linkedin.png";
import twitterSVG from "../assets/images/speakers/twitter.png";
import rajesh_kumar from "../assets/images/speakers/Rajesh-Purushottam-black.jpg";

export default function NFCVR() {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 540px)",
  });

  return (
    <div className="Spbody Spbody1">
      <h2 className="SpHeading">AI in Healthcare</h2>
      <p className="SpInfo">
      Join us for a tech talk on AI in healthcare, where we'll explore the latest developments and applications of this cutting-edge technology.
      </p>
      <div className="mappingContainer"></div>
      <div>
        {!isMobileDevice ? (
          <div className="speaker-card-right-container">
            <div className="name-holder-R">
              <p className="name-R">Rajesh Kumar Purushottam</p>
              <p className="designation-R">
              Co-founder & CEO at In-Med Prognostics
              </p>
            </div>
            <div className="bottom-part-R">
              <div className="profile-picture-div-R">
                <img className="profile-image-R" src={rajesh_kumar} alt="" />
              </div>
              <div className="right-part-R">
                <div className="description-R">
                Rajesh pursued a career in engineering but eventually switched to Human Resources (HR) after realizing his passion for talent management. He worked for several well-known companies and even worked as an HR consultant abroad. Rajesh is intellectually curious, persevering and optimistic, emotive, innovative, and imaginative. He noticed a decline in the quality of talent and leadership in companies and became concerned about the lack of accountability. These concerns led him to focus more on talent management and the need for better leadership.
                </div>
                <div className="icons-R">
                  <a
                    href="https://www.linkedin.com/in/rajesh-kumar-purushottam-7849874/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedinSVG} alt="" />
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagramSVG} alt="" />
                  </a>
                  <a
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitterSVG} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="Parent_div">
            <div className="img_info">
              <div className="Picture_div">
                <img className="profile-image" src={rajesh_kumar} alt="" />
              </div>
              <div className="Designation_div">
                <p className="name">Rajesh Kumar Purushottam</p>
                <p className="designation">
                Co-founder & CEO at In-Med Prognostics
                </p>
              </div>
            </div>
            <div className="description">
            Rajesh pursued a career in engineering but eventually switched to Human Resources (HR) after realizing his passion for talent management. He worked for several well-known companies and even worked as an HR consultant abroad. Rajesh is intellectually curious, persevering and optimistic, emotive, innovative, and imaginative. He noticed a decline in the quality of talent and leadership in companies and became concerned about the lack of accountability. These concerns led him to focus more on talent management and the need for better leadership.
            </div>

            <div className="Icons_div">
              <a
                href="https://www.linkedin.com/in/rajesh-kumar-purushottam-7849874/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={linkedinSVG} alt="" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={instagramSVG} alt="" />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noreferrer"
              >
                <img className="IndivIcons" src={twitterSVG} alt="" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
